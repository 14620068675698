(function () {

    'use strict';

    const {GlobalWorkerOptions, getDocument} = require('pdfjs-dist');
    const {PDFLinkService, PDFViewer, EventBus} = require('pdfjs-dist/web/pdf_viewer');

    GlobalWorkerOptions.workerSrc = require(
        'file-loader?name=pdf.worker.[contenthash].js&esModule=false!pdfjs-dist/build/pdf.worker'
    );

    class PdfViewerCtrl {
        constructor($scope, $element, $mdConstant, printService) {
            this.$scope = $scope;
            this.$element = $element;
            this.printService = printService;

            this.src = null;
            this.pdfViewer = null;
            this.pdfDocument = null;
            this.pdfLinkService = null;

            this.currentScale = 1;
            this.up = $mdConstant.KEY_CODE.UP_ARROW;
            this.down = $mdConstant.KEY_CODE.DOWN_ARROW;

            this.pagesInit = () => {
                this.pdfViewer.currentScaleValue = 'auto';

                this.refreshPages();
                this.refreshScale();

                this.$scope.$applyAsync();
            }

            this.pageChanging = () => {
                this.refreshPages();
                this.$scope.$applyAsync();
            }
        }

        static get $inject() {
            return ["$scope", "$element", "$mdConstant", "printService"];
        }

        $onInit() {
            const container = $('.viewerContainer', this.$element).get(0);

            this.eventBus = new EventBus();
            this.pdfLinkService = new PDFLinkService({eventBus: this.eventBus});

            this.eventBus.on('pagesinit', this.pagesInit);
            this.eventBus.on('pagechanging', this.pageChanging);

            this.promise = getDocument(this.src).promise.then(document => {
                // make viewer async creation async (issue with position)s
                this.pdfViewer = new PDFViewer({container, linkService: this.pdfLinkService, eventBus: this.eventBus});
                this.pdfLinkService.setViewer(this.pdfViewer);


                this.pdfDocument = document;
                this.pdfViewer.setDocument(this.pdfDocument);
                this.pdfLinkService.setDocument(this.pdfDocument, null);
            });

            this.$element.on('keydown', (ev) => this.handlePress(ev));
        }

        $onDestroy() {
            this.$element.off('keydown');

            this.eventBus.off('pagesinit', this.pagesInit);
            this.eventBus.off('pagechanging', this.pageChanging);

            delete this.eventBus;
            delete this.pdfViewer;
            delete this.pdfLinkService;

            this.eventBus = null;
            this.pdfViewer = null;
            this.pdfLinkService = null;

            if (this.pdfDocument) {
                this.pdfDocument.destroy();
                this.pdfDocument = null;
            }
        }

        handlePress(ev) {
            if (!_.includes([this.up, this.down], ev.keyCode)) return;

            if (this.up === ev.keyCode && this.currentPage !== 1) this.previous();
            if (this.down === ev.keyCode && this.currentPage !== this.totalPages) this.next();
        }

        refreshPages() {
            this.totalPages = this.pdfViewer.pagesCount;
            this.currentPage = this.pdfViewer.currentPageNumber;
        }

        refreshScale() {
            this.currentScale = this.pdfViewer.currentScale;
        }

        next() {
            this.pdfViewer.currentPageNumber = this.currentPage + 1;
        }

        previous() {
            this.pdfViewer.currentPageNumber = this.currentPage - 1;
        }

        zoomOut() {
            this.pdfViewer.currentScale -= .2;
            this.refreshScale();
        }

        firstPage() {
            this.pdfViewer.currentPageNumber = 1;
            this.refreshPages();
        }

        lastPage() {
            this.pdfViewer.currentPageNumber = this.totalPages;
            this.refreshPages();
        }

        resetZoom() {
            this.pdfViewer.currentScale = 1;
            this.refreshScale();
        }

        zoomIn() {
            this.pdfViewer.currentScale += .2;
            this.refreshScale();
        }

        print() {
            this.promise = this.pdfDocument.getData()
                .then(buffer => {
                    let blob = new Blob([buffer], {type: "application/pdf"});
                    this.printService.printBlob(blob);
                });
        }

        rotateLeft() {
            this.pdfViewer.pagesRotation += 90;
            this.pdfViewer.currentScaleValue = 'auto';

            this.refreshScale();
        }

        rotateRight() {
            this.pdfViewer.pagesRotation -= 90;
            this.pdfViewer.currentScaleValue = 'auto';

            this.refreshScale();
        }

    }

    module.exports = {
        bindings: {
            src: '@'
        },
        controllerAs: "vm",
        controller: PdfViewerCtrl,
        template: require('../views/pdf-viewer.tpl.html'),
    };

})();
