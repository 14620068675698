/**
 * Created by Betalos on 27/06/2017.
 */
(function () {

    'use strict';

    const ORDER_DIALOG = require('../dialogs/dcm-order/dcm-order-dialog');
    const DCM_INSTANCES_DIALOG = require('../dialogs/dcm-instances/dcm-instances-dialog');
    const FIRE_PACS_STUDIES_DIALOG = require('../dialogs/fire-pacs-studies/fire-pacs-studies-dialog');

    module.exports = dcmService;

    dcmService.$inject = [
        "$q", "$http", "mnWebSocket", "$mdDialog", "patientService", "configService", "$mdToast", "$translate",
        "$timeout"
    ];

    function dcmService(
        $q, $http, mnWebSocket, $mdDialog, patientService, configService, $mdToast, $translate, $timeout
    ) {
        let self = this;

        self.getModalities = getModalities;
        self.getAets = getAets;

        self.handleAet = handleAet;
        self.removeAet = removeAet;
        self.updateModality = updateModality;

        self.getViewers = getViewers;
        self.handleViewer = handleViewer;
        self.removeViewer = removeViewer;

        // Requested Procedure
        self.getRequestedProcedures = getRequestedProcedures;
        self.handleRequestedProcedures = handleRequestedProcedures;
        self.removeRequestedProcedures = removeRequestedProcedures;

        self.getDcmServiceConfigs = getDcmServiceConfigs;
        self.handleDcmServiceConfig = handleDcmServiceConfig;
        self.startDcmService = startDcmService;
        self.stopDcmService = stopDcmService;
        self.restartDcmService = restartDcmService;

        self.createDcmOrder = createDcmOrder;
        self.saveDcmOrder = saveDcmOrder;

        self.importDcmInstance = importDcmInstance;
        self.importLastDcmInstance = importLastDcmInstance;
        self.handleDcmInstance = handleDcmInstance;

        // fire pacs integration
        self.integrationConfig = {is_active: false};
        self.getIntegrationConfig = getIntegrationConfig;
        self.setIntegrationConfig = setIntegrationConfig;

        self.getFirePacsPreviews = getFirePacsPreviews;
        self.handleFirePacsStudy = handleFirePacsStudy;
        self.importFirePacsStudy = importFirePacsStudy;
        self.importLastFirePacsStudy = importLastFirePacsStudy;

        function getModalities() {
            const deferred = $q.defer();
            const url = '/api/dcm-modality/';

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getAets() {
            const deferred = $q.defer();
            const url = '/api/dcm-aet/';

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }


        function handleAet(aet) {
            const deferred = $q.defer();
            const url = `/api/dcm-aet/${aet.id ? aet.id + '/' : ''}`;

            $http.post(url, aet)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeAet(aet) {
            return $http.delete(`/api/dcm-aet/${aet.id}/`);
        }

        function updateModality(modality) {
            const deferred = $q.defer();
            const url = `/api/dcm-modality/${modality.id}/`;

            $http.post(url, modality)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        // viewer configs
        function getViewers() {
            const deferred = $q.defer();
            const url = '/api/dcm-viewer-config/';

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleViewer(viewer) {
            const deferred = $q.defer();
            const url = `/api/dcm-viewer-config/${viewer.id ? viewer.id + '/' : ''}`;

            $http.post(url, viewer)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeViewer(viewer) {
            const url = `/api/dcm-viewer-config/${viewer.id}/`;
            return $http.delete(url);
        }

        // requested procedures
        function getRequestedProcedures() {
            const deferred = $q.defer();
            const url = '/api/dcm-requested-procedure/';

            $http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function handleRequestedProcedures(procedure) {
            const deferred = $q.defer();
            const url = `/api/dcm-requested-procedure/${procedure.id ? procedure.id + '/' : ''}`;

            $http.post(url, procedure)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeRequestedProcedures(procedure) {
            const url = `/api/dcm-requested-procedure/${procedure.id}/`;
            return $http.delete(url);
        }

        function getDcmServiceConfigs() {
            const deferred = $q.defer();
            const url = '/api/dcm-service-config/';

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleDcmServiceConfig(config) {
            const deferred = $q.defer();
            const url = `/api/dcm-service-config/${config.id ? config.id + '/' : ''}`;

            $http.post(url, config)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function startDcmService(config) {
            return mnWebSocket.call("dcm.DcmServiceHandler.start_service", _.pick(config, ["type", "service_name"]));
        }

        function stopDcmService(config) {
            return mnWebSocket.call("dcm.DcmServiceHandler.stop_service", _.pick(config, ["type", "service_name"]));
        }

        function restartDcmService(config) {
            return mnWebSocket.call("dcm.DcmServiceHandler.restart_service", _.pick(config, ["type", "service_name"]));
        }

        function createDcmOrder(patient, ev) {
            $mdDialog.show(_.assign({}, ORDER_DIALOG, {
                targetEvent: ev,
                locals: {
                    patient_id: patient
                }
            }));
        }

        function saveDcmOrder(order) {
            const deferred = $q.defer();
            const url = `/api/dcm-modality-work-item/${order.id ? order.id + '/' : ''}`;

            $http.post(url, order)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function importDcmInstance(patient, preSave, context, ev, resolve = false) {
            const deferred = $q.defer();

            patientService.getExtraMinimalPatient(patient)
                .then(done);

            function done(data) {
                const query = {patient_first_name: data['first_name'], patient_last_name: data['last_name']};
                const dialog = _.assign({}, DCM_INSTANCES_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        query, preSave, context, resolve, patient, fullName: data['full_name']
                    }
                });

                $mdDialog.show(dialog)
                    .then(instance => deferred.resolve(instance), deferred.reject);
            }

            return deferred.promise;
        }

        function importLastDcmInstance(patient, preSave, context, resolve = false) {
            const deferred = $q.defer();

            mnWebSocket.call('dcm.DcmInstanceImport.patient_last_dcm_instance', {patient})
                .then(success, error);

            function success(instance) {
                if (instance) {
                    if (resolve) deferred.resolve(instance);
                    else if (!_.isNil(preSave)) preSave().then(() => startSave(instance));
                    else startSave(instance);
                } else error();
            }

            function startSave(instance) {
                handleDcmInstance(patient, instance, context).then(deferred.resolve, error);
            }

            function error() {
                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('dcm_patient_has_no_study'))
                    .position("bottom left")
                    .hideDelay(2500);

                $timeout(() => {
                    $mdToast.show(simpleToast);
                    deferred.reject();
                }, 100);
            }

            return deferred.promise;
        }

        function handleDcmInstance(patient, instance, context) {
            return mnWebSocket.call('dcm.DcmInstanceImport.handle_imported_dcm_files', {
                patient, context, instance: instance.id
            })
        }

        // firePACS integration
        function getIntegrationConfig() {
            const deferred = $q.defer();

            configService.get('fire_pacs_config', false)
                .then(data => {
                    self.integrationConfig = data || {is_active: false};
                    deferred.resolve(data);
                }, deferred.error);

            return deferred.promise;
        }

        function setIntegrationConfig(config) {
            const deferred = $q.defer();

            configService.set({"fire_pacs_config": config})
                .then(() => {
                    self.integrationConfig = config;
                    deferred.resolve(config);
                }, deferred.error);

            return deferred.promise;
        }

        function importFirePacsStudy(patient, preSave, context, ev, resolve = false) {
            const deferred = $q.defer();

            patientService.getExtraMinimalPatient(patient)
                .then(done);

            function done(data) {
                const query = {PatientName: data['dcm_full_name']};
                const dialog = _.assign({}, FIRE_PACS_STUDIES_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        query, preSave, context, resolve, patient, fullName: data['full_name']
                    }
                });

                $mdDialog.show(dialog)
                    .then(instance => deferred.resolve(instance), deferred.reject);
            }

            return deferred.promise;
        }

        function importLastFirePacsStudy(patient, preSave, context, resolve = false) {
            const deferred = $q.defer();

            mnWebSocket.call('dcm.FirePacsStudies.patient_last_study', {patient})
                .then(success, error);

            function success(study) {
                if (study) {
                    if (resolve) deferred.resolve(study);
                    else if (!_.isNil(preSave)) preSave().then(() => startSave(study));
                    else startSave(study);
                } else error();
            }

            function startSave(study) {
                handleFirePacsStudy(patient, context, study).then(deferred.resolve, error);
            }

            function error() {
                let simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('dcm_patient_has_no_study'))
                    .position("bottom left")
                    .hideDelay(2500);

                $timeout(() => {
                    $mdToast.show(simpleToast);
                    deferred.reject();
                }, 100);
            }

            return deferred.promise;
        }

        function getFirePacsPreviews(study) {
            return mnWebSocket.call('dcm.FirePacsStudies.previews', study.Series);
        }

        function handleFirePacsStudy(patient, context, study) {
            return mnWebSocket.call('dcm.FirePacsStudies.import_study', {patient, context, study});
        }
    }

})();
