/**
 * Created by amine on 22/03/2017.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const mnHumanSkeleton = require('./components/human-skeleton');
    const TraumatoContainerCtrl = require('./controllers/TraumatoContainerCtrl');

    const appointmentFormButton = require('./components/appointment-form-button')

    const pregnancyBlock = require('./components/pregnancy-block');
    const gynecologyInfo = require('./components/gynecology-info');
    const pregnancyTerm = require('./components/pregnancy-term');
    const pregnancyService = require('./services/pregnancyService');

    const ophthalmicService = require('./services/ophthalmicService');
    const ophthalmicPrescription = require('./components/ophthalmic-prescription');

    const vaccinationService = require('./services/vaccinationService');
    const vaccinationCalendar = require('./components/vaccination-calendar-block');
    const vaccinationAgeInterval = require('./components/vaccination-age-interval');

    const mnHurExam = require('./components/hur-exam');

    // const observationBlock = require('./components/observation-block');
    const observationService = require('./services/observationService');
    const ptBlock = require('./components/prothrombin-time-block');
    const ptService = require('./services/ptService');

    const hurService = require('./services/hurService');

    const pathologyBlock = require('./components/pathology-block');
    const pathologyService = require('./services/pathologyService');

    const growthCharts = require('./directives/growth-charts');
    const GXPService = require('./services/gxpService');

    angular
        .module("medinet")

        .controller('TraumatoContainerCtrl', TraumatoContainerCtrl)
        .component('mnHumanSkeleton', mnHumanSkeleton)

        .component('mnAppointmentFormButton', appointmentFormButton)

        .component('mnPregnancyBlock', pregnancyBlock)
        .component('mnGynecologyInfo', gynecologyInfo)
        .component('mnPregnancyTerm', pregnancyTerm)
        .service('pregnancyService', pregnancyService)

        .service('ophthalmicService', ophthalmicService)
        .component('mnOphthalmicPrescription', ophthalmicPrescription)

        .service('vaccinationService', vaccinationService)
        .component('mnAgeInterval', vaccinationAgeInterval)
        .component('mnVaccinationCalendar', vaccinationCalendar)

        .service('observationService', observationService)

        .component('mnPtBlock', ptBlock)
        .service('ptService', ptService)

        .component('mnHurExam', mnHurExam)
        .service('hurService', hurService)

        .component('mnPathologyBlock', pathologyBlock)
        .service('pathologyService', pathologyService)

        .directive('growthCharts', growthCharts)
        .service('gxpService', GXPService)
})();