/**
 * Created by amine on 05/10/2020.
 */
(function () {

    'use strict';


    class GrowthXPSetupDialog {
        constructor(configService, gxpService, $mdDialog, $http, $mdToast, $translate) {
            this.configService = configService;
            this.gxpService = gxpService;
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.http = $http;
            this.translate = $translate;

            this.pages = [];
            this.config = null;
        }

        static get $inject() {
            return ["configService", "gxpService", "$mdDialog", "$http", "$mdToast", "$translate"];
        }

        $onInit() {
            this.configService.getByHttp('gxp_server_config')
                .then(data => {
                    this.config = data || {server_url: "", wax_folder: "", page: ""};
                });
        }

        validateURL(data) {
            this.config.wax_folder = data['wax-server'];
            this.pages = data.pages;
        }

        invalidURL() {
            console.log("error");
        }

        fixMeasures() {
            this.gxpService.fixMeasures().then(() => {
                //toast
                let simpleToast = this.toast.simple()
                    .textContent(this.translate['instant']('measures_setup_fix_for_gxp_toast'))
                    .position("bottom left")
                    .hideDelay(2500);

                this.toast.show(simpleToast);
            });
        }

        submit() {
            this.configService.set({"gxp_server_config": this.config})
                .then(() => {
                    this.dialog.hide(true);
                });
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: GrowthXPSetupDialog,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        template: require("../views/growth-setup.tpl.html"),
    };

})();

